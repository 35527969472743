
import { mask } from "vue-the-mask"
import { SelectModel } from "@/core/models/SelectModel"
import { Branch } from "@/core/models/Branch"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, ref, onMounted, watch } from "vue"
import { useRouter } from "vue-router"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { ElForm, FormItemRule } from "element-plus"
import { phoneMask } from "@/core/helpers/masks"
import { QuillEditor } from "@vueup/vue-quill"

export default defineComponent({
  name: "branch-component",
  components: {},
  directives: { mask },
  props: ["id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()

    const branchData = ref<Branch>({ isActive: true } as Branch)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const businessList = ref<SelectModel[]>([])

    const schoolCodeLabel = computed(
      () => ((branchData.value && branchData.value.type) == 2 ? "Kurs" : "Okul") + " Kodu"
    )

    var corporationList = ref<SelectModel[]>([])

    var branchGroupList = ref<SelectModel[]>([])
    type QuillInstance = InstanceType<typeof QuillEditor>
    const editor = ref<QuillInstance>()

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      corporationId: getRule(RuleTypes.SELECT, "Şirket"),
      branchGroupId: getRule(RuleTypes.SELECT, "Bölge"),
      title: getRule(RuleTypes.TEXT50, "Şube Adı"),
      type: getRule(RuleTypes.SELECT, "Tür"),
      schoolCode: getRule(RuleTypes.TEXT50, schoolCodeLabel.value),
      phone: getRule(RuleTypes.PHONE, "Soyad"),
      email: getRule(RuleTypes.MAIL),
      ssiRegistrationNo: getRule(RuleTypes.TEXT50, "SGK Sicil Numarası"),
      address: [
        {
          required: true,
          message: `Lütfen Adres Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
        {
          min: 0,
          max: 100,
          message: `Lütfen 100 Karakter Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
      ],
    })

    var allSchoolTypes = ref([
      { id: 1, name: "Okul" },
      { id: 2, name: "Kurs" },
      { id: 3, name: "Dil Kursu" },
    ])

    const getBranch = async id => {
      const { data } = await ApiService.get("branch/" + id)
      branchData.value = data
      getBranchGroupList(data.businessId)
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getBranch(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined)

    onMounted(async () => {
      await getBusinessList()
      await getCorporationList()
      await initDefaultValues()
    })

    const initDefaultValues = async () => {
      setDefaultValue(businessList, "businessId")
      setDefaultValue(corporationList, "corporationId")
      const businessId = branchData.value.businessId
      if (businessId) {
        await getBranchGroupList(businessId)
        setDefaultValue(branchGroupList, "branchGroupId")
      }
    }

    const setDefaultValue = (list, property) => {
      if (list.value.length === 1) {
        branchData.value[property] = list.value[0].id
      }
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
    }

    const getCorporationList = async () => {
      const { data } = await ApiService.get("corporation")
      corporationList.value = data.map(x => ({ name: x.title, id: x.id }))
    }

    const getBranchGroupList = async businessId => {
      const { data } = await ApiService.get("branch-group/business/" + businessId)
      branchGroupList.value = data
    }

    async function updateBranch() {
      const data = {
        ...branchData.value,
        phone: branchData.value.phone.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", ""),
        id: props.id,
      }
      await ApiService.post("branch/edit", data)
      emit("getlist")
      router.push({ name: "branch-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          updateBranch()
        }
      })
    }

    const OnChangeBusiness = () => {
      branchData.value.branchGroupId = ""
      branchData.value.corporationId = ""
      getBranch(branchData.value.businessId)
      getBranchGroupList(branchData.value.businessId)
    }

    const OnReadyQuill = event => {
      console.log(event)
    }

    return {
      showDrawer,
      handleClose: props.closed,
      branchData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      corporationList,
      getBranchGroupList,
      branchGroupList,
      allSchoolTypes,
      rules,
      OnChangeBusiness,
      phoneMask,
      editor,
      OnReadyQuill,
      schoolCodeLabel,
    }
  },
})
